<template>
	<div style="width: 100%;height: 100vh;background-image: linear-gradient(white, #b5d6ff);">
		<div>
			<img style="width: 80%;margin-left: 11%;margin-top: 5%" src="https://czsc.oss-cn-beijing.aliyuncs.com/bj/material_turnover.png"/>
		</div>
		<div style="margin-top: 50%;text-align: center">
			<el-button style="width: 80%;height: 10vh;font-size: 30px" type="primary" @click="goToNewMaterial">新增材料</el-button>
		</div>
		<div style="margin-top: 40%;text-align: center">
			<el-button style="width: 80%;height: 10vh;font-size: 30px" type="primary" @click="goToSelectMaterial">查询材料</el-button>
		</div>
		<div>

		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "Material_turnover",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			bdst: ''
		}
	},
	created() {
		// if (!this.$route.params.key){
		// 	this.$router.push("/error")
		// }else {
		// 	this.bdst = this.$route.params.bdst
		// }
		this.bdst = 1
	},
	methods: {
		uuid() {
			var s = [];
			var hexDigits = '0123456789abcdef';
			for (var i = 0; i < 36; i++) {
				s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
			}
			s[14] = '4';
			s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
			s[8] = s[13] = s[18] = s[23] = '-';

			this.uuidA = s.join('');
			return this.uuidA;
		},
		goToNewMaterial(){
			const time = Date.parse(new Date()) + this.uuid();
			this.$router.push({
				name: "New_material_turnover",
				params: {
					key: time,
					bdst: this.bdst
				}
			});
		},
		goToSelectMaterial(){
			const time = Date.parse(new Date()) + this.uuid();
			this.$router.push({
				name: "Select_material_turnover",
				params: {
					key: time,
					bdst: this.bdst
				}
			});
		},

	}
}
</script>

<style>

</style>